import React, { useState } from 'react';
import Zoom from 'react-reveal/Zoom';
import config from '../../config';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import { GatsbyImage } from "gatsby-plugin-image"
import '../assets/sass/_team.scss';
import { navigate, graphql } from 'gatsby';
import { Multiselect } from 'multiselect-react-dropdown';

const IndexPage = ({ data, ...props }) => {
  const [current, setCurrent] = useState([]);
  const [name, setName] = useState("");
  if (!data.allPrismicAuthor.edges) {
    return <></>;
  }
  
  
  let specialities = ["Todos", ...data.allPrismicAuthor.edges
    .filter((v) => v.node.data.listed)
    .map((v) => v.node.data.maincategory).flat()
    .map((v) => v.category)
    .filter(function (elem, index, self) {
      return !elem || index === self.indexOf(elem);
    }).sort()].filter(e=>e);
  const setCategory = (lst) => {
    // if we want to display all items
    setCurrent(lst.indexOf("Todos")>-1?[]:lst);
  };  
  return (
    <Layout
      title={'Os nossos especialistas'}
      description="Conheça os nossos especialistas e agende a sua consulta"
    >
      <Header
        className="pro"
        title="Os nossos especialistas"
        smallSubtitle="Conheça os nossos especialistas e agende a sua consulta"
        active="profissionais"
        subtitle="Conheça os especialistas que fazem da EMMIM um lugar melhor"
      />
      <div className="crew-container">
        <div className="content container ">
          <div className="row p-5 filter no-gutters">
            <div className="col-sm-12 col-md-1"></div>
            <div className="col-sm-12 col-md-5">
              {typeof window !== 'undefined' && (
                <Multiselect
                  placeholder="Procure por especialidade"
                  options={specialities}
                  isObject={false}
                  onSelect={setCategory}
                  onRemove={setCategory}
                  selectedValues={current}
                />
              )}
            </div>
            <div className="col-sm-12 col-md-5">
              <span className="searchName">
                <input
                  onChange={(e) => setName(e.target.value?.toLowerCase())}
                  placeholder="Procure por nome"
                ></input>
              </span>
            </div>
            <div className="col-sm-12 col-md-1"></div>
          </div>
          <div className="row p-5">
            <div className="col-sm-12 col-md-12">
              <div className="container-fluid crew-container">
                <div className="row">
                  {data.allPrismicAuthor.edges
                    .filter((v) => v.node.data.listed)
                    .map(({ node }) => {
                      let v = node.data;
                      return (
                        ((current.length === 0 && !name) ||
                          (name && v.name.toLowerCase().indexOf(name) > -1) ||
                          (v.list !== 'no' &&
                            v.maincategory &&
                            v.maincategory.filter(
                              (v) => current.indexOf(v.category) > -1
                            ).length > 0)) && (
                          <Zoom key={v.name}>
                            <div
                              className="col-md-4 col-sm-6 "
                              onClick={() => navigate(`/${v.internallink}/`)}
                            >
                              <div className="our-team active">
                                <div className="pic">
                                  <GatsbyImage
                                    className={'image img-fluid'}
                                    style={{width: 500, height:350}}
                                    alt={
                                      v.specialities &&
                                      v.specialities
                                        .map((v) => v.speciality)
                                        .join(' // ')
                                    }
                                    image={v.picture.gatsbyImageData}
                                  />
                                </div>
                                <div className="content">
                                  <SocialLinks
                                    sectionP={false}
                                    links={[
                                      {
                                        platform: 'site',
                                        link: {
                                          url: `${config.url}${config.pathPrefix}${v.internallink}/`,
                                        },
                                      },
                                      ...v.socialmedias,
                                    ]
                                      .map(
                                        (s) =>
                                          s.link?.url && {
                                            type: s.platform,
                                            url: s.link.url,
                                          }
                                      )
                                      .filter((v) => v)}
                                  />
                                  <h3 className="title">
                                    <a
                                      className="title text-color"
                                      href={`${config.url}${config.pathPrefix}${v.internallink}/`}
                                    >
                                      {' '}
                                      {v.name}
                                    </a>
                                  </h3>
                                  <span className="post">
                                    {v.specialities &&
                                      v.specialities &&
                                      v.specialities

                                        .map(
                                          (v, k) =>
                                            k < 2 && (
                                              <div key={`sp-${v}-${k}`}>
                                                {v.speciality}
                                              </div>
                                            )
                                        )
                                        .filter((e) => e)}
                                    {v.specialities &&
                                      v.specialities.length > 2 && (
                                        <div>(ver mais especialidades)</div>
                                      )}
                                  </span>
                                  {v.book && (
                                    <a
                                      onClick={() =>
                                        navigate(`/${v.internallink}#contacts`)
                                      }
                                      className={`btn-cta btn-white p-2`}
                                    >
                                      Marcar Consulta
                                    </a>
                                  )}
                                  {/* { <span className="post">
                                {v.timetable.map((t, i) => (
                                  <p>
                                    <div className={"text-color"} key={i}>
                                      {t.day}
                                    </div>
                                    <div key={i}>
                                      {t.startTime} às {t.endTime}
                                    </div>
                                  </p>
                                ))}
                              </span>} */}
                                </div>
                              </div>
                            </div>
                          </Zoom>
                        )
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SocialLinks />
      <Footer />
    </Layout>
  );
};
export const pageQuery = graphql`
  query {
      allPrismicAuthor(
      sort: {data: {name: ASC}}
      filter: {data: {listed: {eq: true}, customer: {eq: true}}}
    ) {
      edges {
        node {
          data {
            name
            listed
            internallink
            maincategory {
              category
            }
            specialities {
              speciality
            }
            socialmedias {
              platform
              link {
                link_type
                url
              }
            }
            timetable {
              day
              starttime
              endtime
            }
            book
            picture {
              url
              gatsbyImageData
            }
            description {
              html
            }
          }
        }
      }
    }
  }
`;
export default IndexPage;
