import React from 'react';
import config from '../../config';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faRss } from '@fortawesome/free-solid-svg-icons/faRss';

const _icons = {
  site: ['fas', 'link'],
  instagram: ['fab', 'instagram'],
  facebook: ['fab', 'facebook-f'],
  linkedin: ['fab', 'linkedin-in'],
  email: ['fas', 'envelope'],
  phoneNr: ['fas', 'phone'],
  rss: ['fas', 'rss'],
};

const shareButtonsIcons = [
  faLink,
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faYoutube,
  faEnvelope,
  faPhone,
  faRss
];

library.add(...shareButtonsIcons);
export default function SocialLinks({
  sectionP = true,
  links = config.socialLinks,
}) {
  let socialLinks = links.map((social) => {
    let { icon, url="", type, title = '' } = social;
    
    return (
      <a key={url} href={url} target="_blank" rel="noopener noreferrer" className={`mx-2`} title={title}>
        <FontAwesomeIcon icon={icon || _icons[type]} />
      </a>
    );
  });

  return sectionP ? (
    <section className="social-section bg-fg">
      <div className="social d-flex justify-content-center">{socialLinks}</div>
    </section>
  ) : (
    <span className="social">{socialLinks}</span>
  );
}
