import React from 'react';
import logo from '../assets/img/logo.png';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart';

library.add(...[faHeart]);
export default function Footer() {
  return (
    <footer className="bg-fg small text-center text-white">
      <div className="container">
        
        <div className="row">
          <div className="col text-center">
            <a  href="https://www.emmim.pt/" target="_blank" rel="noreferrer" >
              <img src={logo} className="logo" alt={`Logo`} />
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col text-center">
            <a
              style={{ color: 'white', opacity: '0.8', marginLeft: '16px' }}
              
              href="https://www.emmim.pt/cookies/"
              target="_blank" rel="noreferrer" 
            >
              {' '}
              Cookies{' '}
            </a>
            <a
              style={{ color: 'white', opacity: '0.8', marginLeft: '16px' }}
              
              href="https://www.emmim.pt/privacidade/"
              target="_blank" rel="noreferrer" 
            >
              {' '}
              Politica Privacidade{' '}
            </a>
            <a
              style={{ color: 'white', opacity: '0.8', marginLeft: '16px' }}
              
              href="https://www.emmim.pt/termos/"
              target="_blank" rel="noreferrer" 
            >
              {' '}
              Termos{' '}
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col text-center p-5">
            Made with
            <FontAwesomeIcon icon={'heart'} /> by
            <a
              
              href="https://www.emmim.pt/"
              target="_blank" rel="noreferrer" 
            >
              EMMIM
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
